/* You can add global styles to this file, and also import other style files */

html, body { height: 100vh; width: 100vw;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #eeeeee;}

.header-less-tabs.mat-tab-group .mat-tab-header {
    display: none;
}

a {
    color: #0a4d88;
}

.shadow-img {
    -webkit-filter: drop-shadow(2px 2px 2px #222);
    filter: drop-shadow(2px 2px 2px #222);
}

.gradient-back {
    /* background: #0a4d88; */
}

strong {
    font-weight: 500;
}

.card {
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    min-height: 250px;
    max-width: 400px;
    padding: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.cdk-global-overlay-wrapper {
    overflow: auto;
}